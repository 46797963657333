import { useCallback, useState } from 'react'
import Button, { LoadingState, setLoadingState } from '../../ui/Button'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppSelector } from 'store'
import translate from 'utils/translate'
import Modal from 'components/common/Modal'
import { subscribeToStocksMail } from 'framework/litium'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { create } from 'zustand'
import { z } from 'zod'
import useUIStore from 'store/ui'

interface PartialVariant {
  code: string
  name: string
}
interface NotifyDialogStore {
  variant: PartialVariant | null
  setVariant: (variant: PartialVariant | null) => void
}

export const useNotifyDialog = create<NotifyDialogStore>((set) => ({
  variant: null,
  setVariant: (variant) => set({ variant }),
}))

const schema = z.object({
  email: z.string().email(),
})

type NotificationFormType = z.infer<typeof schema>

const NotifyMeDialog = () => {
  const { country } = useAppSelector((s) => s.channel)
  const { variant, setVariant } = useNotifyDialog()

  const ui = useUIStore()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NotificationFormType>({
    resolver: zodResolver(schema),
  })

  const [btnState, setBtnState] = useState<LoadingState>('')

  const onSubmit: SubmitHandler<NotificationFormType> = useCallback(
    async (data) => {
      setLoadingState(async () => {
        if (!variant) return
        const request = {
          email: data.email,
          variantId: variant.code,
          subscribe: true,
          productUrl: window.location.href,
        }
        await subscribeToStocksMail(request)
        setVariant(null)
        ui.showToast({
          message: translate('notifysuccess'),
          type: 'info',
        })
      }, setBtnState)
    },
    [ui, setVariant, variant],
  )

  return (
    <Modal
      isOpen={!!variant}
      maxWidth="max-w-3xl"
      onClose={() => setVariant(null)}
    >
      <div className="px-4 py-5 sm:p-6 relative">
        <div className="absolute top-4 right-4 flex items-center">
          <button
            type="button"
            className="-m-2 p-2 text-primary hover:text-primary-2"
            onClick={() => setVariant(null)}
          >
            <span className="sr-only">{translate('close')}</span>
            <XMarkIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
        <h3 className="text-h-lg font-bold text-primary">
          {translate(
            country === 'us' || country === 'ca' ? 'notifyMe' : 'outOfStock',
          )}
        </h3>
        <div className="mt-2 font-medium text-sm text-primary">
          <p>{variant?.name}</p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="mt-5 flex flex-col sm:flex-row sm:items-center gap-4"
        >
          <div className="w-full">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              {...register('email')}
              type="email"
              className={`px-4 h-11 text-[15px] font-medium text-sans block w-full sm:text-sm  text-primary border-2 rounded-full ${
                errors.email
                  ? 'border-accent focus:ring-accent focus:border-accent'
                  : 'border-neutral-4 focus:ring-primary-2 focus:border-primary-2'
              }
                      }`}
              placeholder="your@email.com"
            />
          </div>
          <div className="flex-shrink-0">
            <Button
              state={btnState}
              disabled={btnState === 'loading'}
              className="w-full sm:w-auto"
            >
              {translate('notifyMe')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default NotifyMeDialog
