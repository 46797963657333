import Modal from 'components/common/Modal'
import RichText from 'components/common/RichText'
import translate from 'utils/translate'

interface Props {
  isOpen: boolean
  onClose: () => any
  sizeGuide: string | null
}

const SizeGuide = ({ isOpen, onClose, sizeGuide }: Props) => {
  return (
    <Modal isOpen={isOpen} maxWidth="max-w-3xl" onClose={onClose}>
      <div className="max-h-[480px] overflow-auto px-5 pb-5">
        <h3 className="text-h-lg font-bold text-primary mt-5">
          {translate('sizechart')}
        </h3>
        {sizeGuide && <RichText className="sizeguide">{sizeGuide}</RichText>}
      </div>
    </Modal>
  )
}

export default SizeGuide
