import Section from 'components/common/Section'
import ListBlock from 'components/list'
import { ListProductItem } from 'framework/strapi/types'
import translate from 'utils/translate'
import { useProduct } from './ProductContext'

const ProductRelations = () => {
  const { variant } = useProduct()
  if (variant.product.productRelations.length <= 0) return null
  const relations: ListProductItem[] = []
  const productRelations = variant.product.productRelations.Accessory || []
  productRelations.map((pr: any) => {
    if (!pr) return
    const r: ListProductItem = {
      id: -8484271313,
      __component: 'list.product-item',
      url: { id: pr, src: pr, type: 'variant' },
    }
    relations.push(r)
  })

  if (!relations.length) return null

  return (
    <Section tw_spacing="yes">
      <ListBlock
        background={null}
        __component="list.list-section"
        cardLayout="Auto"
        cardsGridLayout="Carousel"
        channel={null}
        id={-1213209}
        description={null}
        heading={translate('relatedProducts')}
        link={null}
        showInfo={false}
        list={{
          id: -2232093,
          channel: null,
          link: null,
          slug: null,
          title: null,
          items: relations,
        }}
        settings={{
          __component: 'common.section-settings',
          anchor: null,
          colorTheme: 'Default',
          container: 'Default',
          height: 'Default',
          id: -2939098,
          tw_spacing: '',
        }}
      />
    </Section>
  )
}

export default ProductRelations
