import {
  ProductBundleDataItem,
  VariantViewModel,
} from 'framework/common/variantViewModel'
import { useMemo } from 'react'
import { UseVariantDataResult } from 'store2/use-variant-data'
import { create, type StateCreator } from 'zustand'
import { devtools } from 'zustand/middleware'
type BundleStateItem = { code: string | null; quantity: number }
export type BundleState = Record<string, BundleStateItem>

interface BundleStore {
  bundleOption: ProductBundleDataItem | null
  variantId: string | null
  setBundleOption: (bundleOption: ProductBundleDataItem | null) => void
  state: BundleState
  setState: (updater: (item: BundleState) => BundleState) => void
}

const store: StateCreator<BundleStore> = (set) => ({
  bundleOption: null,
  variantId: null,
  setBundleOption: (bundleOption) => set({ bundleOption }),
  state: {},
  setState: (updater) => {
    set((s) => ({ ...s, state: updater(s.state) }))
  },
})

export const useBundleStore = create<BundleStore>()(
  devtools(store, { name: 'bundle-store' }),
)

/**
 * Updates the bundle state with the new variant data
 * If the variant data is still loading, we keep the quantity from the old state
 * If the stock level is lower than the quantity in the state, we update the quantity to the stock level
 * If we couldn't fetch the variant data for some reason, we keep the quantity from the old state
 * @param state The current state
 * @param variant The variant view model
 * @param vData The variant data
 * @returns The new state
 */
export function updateBundleState(
  state: BundleState,
  variant: VariantViewModel,
  vData: UseVariantDataResult,
  clearState = false,
): BundleState {
  if (!variant.bundleData) return {}
  const newState: BundleState = {}

  // Iterate over the bundle items
  for (const item of variant.bundleData.items) {
    // Find the selected value, or the first one if none is selected
    const selected = item.items.find((o) => o.selected)

    // If no item is selected, we skip the item. This can happen if it's a variant selector where items are optional
    // if (!selected) {
    //   continue
    // }

    newState[item.id] = {
      code: selected?.id ?? null,
      // If the item is a variant and we should not include the original variant, we set quantity to 0
      // Example: 3065459, 380-0360
      quantity:
        !variant.bundleData.includeOriginalVariant && item.type === 'variant'
          ? 0
          : (selected?.quantity ?? 0),
    }
  }

  // Don't overwrite the state with new values if we are clearing the state
  // For example when you switch between variants
  if (clearState) {
    console.log('Clearing bundle state')
    return newState
  }

  // Overwrite with values from the old state if any
  for (const [key, value] of Object.entries(state)) {
    // Make sure all items in the state are still present in the new state
    if (!(key in newState)) {
      continue
    }

    let quantity = value.quantity

    // If we are still loading, we can't know the stock level yet, so we keep the quantity from the old state
    if (vData.isLoading || !value.code) {
      newState[key] = { code: value.code, quantity }
      continue
    }

    // Get the variant data for the current item
    const vd = vData.data[value.code]

    if (!vd || 'noresult' in vd) {
      console.log(`Could not fetch stock status for ${value.code}. (${key})`)
      // Keep the quantity from the old state
      newState[key] = { code: value.code, quantity }
      continue
    }

    const totalStock = vd.stock.inStock + vd.stock.preOrder
    if (totalStock < value.quantity) {
      console.log(
        `Stock level for ${value.code} (${key}) is lower than the quantity in the state`,
      )
      quantity = totalStock
    }

    newState[key] = { code: value.code, quantity }
  }
  return newState
}

interface BundleCalcResult {
  code: string
  price: number
  estimatedDeliveryDays: number
  quantity: number
  stock: number
  loading: false
}

const calculateItem = (
  item: ProductBundleDataItem,
  vData: UseVariantDataResult,
  state: BundleState,
): BundleCalcResult | { loading: true } | null => {
  if (vData.isLoading) {
    return { loading: true }
  }
  const s = state[item.id]
  if (!s) {
    console.warn(`Could not calculate item ${item.id}. No state found`)
    return null
  }

  if (!s.code) {
    // If code is null, it means that the item is a variant selector and no variant is selected
    // We skip since there's nothing to calculate
    return null
  }

  const v = vData.data[s.code]

  // For some reason we couldn't fetch data for this item, return null
  if (!v || 'noresult' in v) {
    console.warn(`Could not calculate item ${item.id}. No variant data found`)
    return null
  }

  const r: BundleCalcResult = {
    price: v.campaignPrice || v.price,
    quantity: s.quantity,
    estimatedDeliveryDays: 0,
    loading: false,
    stock: v.stock.inStock + v.stock.preOrder,
    code: s.code,
  }
  if (v.stock.inStock < s.quantity && v.stock.preOrder > 0) {
    r.estimatedDeliveryDays = v.stock.estimatedDeliveryDays
  }
  return r
}

interface BundleTotalCalcResult {
  totalPrice: number
  estimatedDeliveryDays: number
  preorderItems: string[]
  hasEnoughStock: boolean
  inStock: boolean
  loading: false
}

type BundleTotalCalcResultOrLoading = BundleTotalCalcResult | { loading: true }

export const calculateBundleTotal = (
  variant: VariantViewModel,
  vData: UseVariantDataResult,
  state: BundleState,
): BundleTotalCalcResultOrLoading => {
  let totalPrice = 0
  let estimatedDeliveryDays = 0
  const preorderItems: string[] = []
  let inStock = true
  let hasEnoughStock = true

  if (vData.isLoading) {
    return { loading: true }
  }

  for (const item of variant.bundleData?.items ?? []) {
    const r = calculateItem(item, vData, state)

    // If the item is not present, means that item was not able to be calculated for some reason.
    // CalculateItem will log a warning, so we don't need to do it here.
    // The only thing we can do is to ignore it and continue
    if (!r) {
      continue
    }

    // If the item is still loading we stop the calculation and instead we should return loading
    // so the UI can show a spinner
    if (r.loading) {
      return { loading: true }
    }

    // Calculate the total price and estimated delivery days and stock
    console.log(
      `Adding ${r.price} * ${r.quantity} (${r.price * r.quantity}) to total price: ${totalPrice}`,
    )
    totalPrice += r.price * r.quantity
    if (r.estimatedDeliveryDays > estimatedDeliveryDays) {
      estimatedDeliveryDays = r.estimatedDeliveryDays
    }
    if (r.estimatedDeliveryDays > 0) {
      preorderItems.push(r.code)
    }
    if (r.stock <= 0) {
      inStock = false
    }
    if (r.stock < r.quantity) {
      hasEnoughStock = false
    }
  }

  return {
    totalPrice,
    estimatedDeliveryDays,
    preorderItems,
    hasEnoughStock,
    loading: false,
    inStock,
  }
}

export const useBundleTotal = (
  variant: VariantViewModel,
  vData: UseVariantDataResult,
): BundleTotalCalcResultOrLoading => {
  const state = useBundleStore((s) => s.state)
  return useMemo(
    () => calculateBundleTotal(variant, vData, state),
    [state, vData, variant],
  )
}
