import Container from 'components/ui/Container'
import Script from 'next/script'
import { FC, useEffect } from 'react'

interface Props {
  variantId: string
}

const ProductReviews: FC<Props> = ({ variantId }) => {
  const turnToId = process.env.NEXT_PUBLIC_TURNTO_SITEKEY

  useEffect(() => {
    if (!turnToId) return
    window.turnToConfig = {
      locale: 'en_US',
      pageId: 'pdp-page',
      setupType: 'dynamicEmbed',
      skipOnload: true,
      siteKey: turnToId,
    }
    window.TurnToCmd =
      window.TurnToCmd ||
      function () {
        ;(window.TurnToCmd.q = window.TurnToCmd.q || []).push(arguments)
      }
    window.TurnToCmd('set', { sku: variantId })
    window.TurnToCmd('initialize', 'reviews-summary')
    window.TurnToCmd('initialize', 'reviews-list')
  }, [variantId, turnToId])

  if (!turnToId) return null

  return (
    <>
      <Container layout="Fluid">
        <Script
          strategy="afterInteractive"
          async
          src={`https://widgets.turnto.com/v5/widgets/${turnToId}/js/turnto.js`}
        />
        <div id="tt-reviews-summary"></div>
        <div id="tt-reviews-list" className="mb-16"></div>
      </Container>
    </>
  )
}

export default ProductReviews
