import React from 'react'
import { useRouter } from 'next/router'
import DynamicZone from 'components/DynamicZone'
import { useProduct } from '../ProductContext'

const ProductSections = () => {
  const router = useRouter()
  const { isPreview } = router
  const { variant } = useProduct()

  return (
    <DynamicZone
      components={variant.sections}
      preview={isPreview}
      floatingNavigation={true}
    />
  )
}

export default ProductSections

// function createVideoItems(
//   productSection: ProductSectionContent | null,
// ): CmsGalleryItem[] {
//   const defaultAsset: StrapiFileComponent = {
//     id: '',
//     __component: 'cms.gallery-item',
//     name: '',
//     alternativeText: '',
//     caption: '',
//     formats: {},
//     provider: '',
//     url: '',
//     mime: 'image/jpeg',
//     width: 1024,
//     height: 1024,
//   }

//   const defaultItem: CmsGalleryItem = {
//     id: '',
//     __component: 'cms.youtube-video',
//     asset: defaultAsset,
//     externalVideo: null,
//     thumbnail: null,
//     channel: null,
//     caption: null,
//   }
//   const videos: CmsGalleryItem[] = []

//   if (productSection?.gallery.embed) {
//     const v = productSection?.gallery.embed

//     const item: CmsGalleryItem = {
//       ...defaultItem,
//       id: v.url,
//       asset: {
//         ...defaultAsset,
//         mime: 'embed/video',
//         id: v.url,
//         url: v.url,
//         formats: {
//           large: {
//             mime: 'image/jpeg',
//             url: v.thumbUrl,
//             height: 1024,
//             width: 1024,
//             name: 'Youtube',
//           },
//         },
//       },
//     }
//     videos.push(item)
//   }
//   return videos
// }
