import React, { FC } from 'react'
import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'

interface SelectAmountProps {
  colorTheme?: CommonSectionSettingsColorThemeEnum
  amount: number
  add: () => any
  subtract: () => any
  disabled?: boolean
}

const SelectAmount: FC<SelectAmountProps> = ({
  colorTheme,
  amount,
  add,
  subtract,
  disabled,
}) => {
  // Css
  const css = {
    wrapper: 'flex justify-center items-center',
    button:
      'outline-none flex-shrink-0 w-5 h-5 font-bold rounded-full flex items-center justify-center',
    icon: 'w-4 h-4',
    amount: 'select-none text-center w-8 font-bold text-base',
  }

  // Color Theme
  if (colorTheme === 'Dark') {
    css.button = classNames(css.button, 'bg-white', disabled && 'opacity-50')
    css.icon = classNames(css.icon, 'text-primary')
    css.amount = classNames(css.amount, 'text-white', disabled && 'opacity-50')
  } else {
    css.button = classNames(
      css.button,
      disabled ? 'bg-neutral-4' : 'bg-primary',
    )
    css.icon = classNames(css.icon, 'text-white')
    css.amount = classNames(
      css.amount,
      disabled ? 'text-neutral-4' : 'text-primary',
    )
  }

  return (
    <div className={classNames(css.wrapper, disabled && 'pointer-events-none')}>
      <button
        className={css.button}
        onClick={subtract}
        disabled={disabled}
        aria-label="Remove"
      >
        <MinusIcon className={css.icon} />
      </button>
      <div className={css.amount}>{amount}</div>
      <button
        className={css.button}
        onClick={add}
        disabled={disabled}
        aria-label="Add"
      >
        <PlusIcon className={css.icon} />
      </button>
    </div>
  )
}

export default SelectAmount
