import Button from 'components/ui/Button'
import ButtonWrapper from 'components/ui/ButtonWrapper'
import SelectAmount from 'components/ui/Forms/SelectAmount'
import LoadingDots from 'components/ui/LoadingDots'
import type { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import type { FC, HTMLAttributes } from 'react'
import { useAppSelector } from 'store'
import useUIStore from 'store/ui'
import { useCart } from 'store2/cart'
import {
  type UseVariantDataResult,
  useVariantData,
} from 'store2/use-variant-data'
import { usePrice, useStock } from 'store2/utils'
import translate from 'utils/translate'
import { useBundleStore, useBundleTotal } from './Bundle/BundleStore'
import { useNotifyDialog } from './NotifyDialog'
import { useProduct } from './ProductContext'

interface Props extends HTMLAttributes<HTMLDivElement> {
  colorTheme: CommonSectionSettingsColorThemeEnum
  toggleWorkWithSalesOpen?: () => void
  source?: string
}
const CallToActions: FC<Props> = ({
  colorTheme,
  toggleWorkWithSalesOpen,
  source,
  ...rest
}) => {
  return (
    <div id="productCtas" {...rest}>
      <ButtonWrapper>
        <Inner colorTheme={colorTheme} />
        <AddToQuoteButton colorTheme={colorTheme} />
      </ButtonWrapper>
    </div>
  )
}

interface ActionProps {
  colorTheme: CommonSectionSettingsColorThemeEnum
}

const Inner = ({ colorTheme }: ActionProps) => {
  const { variant, quantity, setQuantity } = useProduct()
  const { showPrices } = useAppSelector((s) => s.channel)
  const ui = useUIStore()
  const vData = useVariantData([variant.code])
  const price = usePrice(variant.code, vData)
  const stock = useStock(variant.code, vData)

  const isLoading = price?.loading || stock.loading || ui.isLoading

  if (isLoading)
    return (
      <div>
        <LoadingDots />
      </div>
    )

  if (!variant.availableOnline || !showPrices) {
    return null
  }

  if (variant.bundleData) {
    return <BundleActions colorTheme={colorTheme} />
  }

  if (stock.inStock === 0) {
    return <NotifyMeButton colorTheme={colorTheme} vData={vData} />
  }

  return (
    <>
      <SelectAmount
        colorTheme={colorTheme}
        amount={quantity}
        add={() => setQuantity(quantity + 1)}
        subtract={() => setQuantity(quantity - 1)}
        disabled={ui.isLoading}
      />
      {quantity > stock.inStock ? (
        <NotifyMeButton colorTheme={colorTheme} vData={vData} />
      ) : (
        <AddToCartButton
          colorTheme={colorTheme}
          disabled={isLoading || !price?.price || quantity > stock.inStock}
        />
      )}
    </>
  )
}

const BundleActions = ({ colorTheme }: ActionProps) => {
  const { variant } = useProduct()
  const vData = useVariantData(variant.allCodes)
  const bundleTotal = useBundleTotal(variant, vData)
  const isLoading = bundleTotal.loading
  if (isLoading)
    return (
      <div>
        <LoadingDots />
      </div>
    )

  if (!bundleTotal.hasEnoughStock) {
    return <NotifyMeButton colorTheme={colorTheme} vData={vData} />
  }

  return (
    <AddToCartButton
      colorTheme={colorTheme}
      disabled={
        isLoading || bundleTotal.totalPrice === 0 || !bundleTotal.hasEnoughStock
      }
    />
  )
}

const AddToCartButton = ({
  colorTheme,
  disabled,
}: ActionProps & { disabled: boolean }) => {
  const { variant, quantity } = useProduct()
  const { state } = useBundleStore()
  const { addToCart } = useCart()
  const ui = useUIStore()

  return (
    <Button
      onClick={() => {
        if (variant.bundleData) {
          // Add all selected bundles to cart
          for (const { code: id, quantity } of Object.values(state)) {
            if (id && quantity) {
              addToCart(id, quantity)
            }
          }
        } else {
          addToCart(variant.code, quantity)
        }
        ui.open('cart')
      }}
      className="flex-grow sm:flex-none"
      colorTheme={colorTheme}
      disabled={disabled}
    >
      {translate('addToCart')}
    </Button>
  )
}

const NotifyMeButton = ({
  colorTheme,
}: ActionProps & { vData: UseVariantDataResult }) => {
  const { variant } = useProduct()
  const notifyDialog = useNotifyDialog()
  const ui = useUIStore()
  if (variant.disableNotifyMe || variant.product.discontinued) return null
  return (
    <Button
      onClick={() =>
        notifyDialog.setVariant({
          code: variant.code,
          name: variant.name,
        })
      }
      className="flex-grow sm:flex-none"
      colorTheme={colorTheme}
      disabled={ui.isLoading}
    >
      {translate('notifyMe')}
    </Button>
  )
}

const AddToQuoteButton = ({ colorTheme }: ActionProps) => {
  const { variant, quantity } = useProduct()
  const ui = useUIStore()
  const { addToCart } = useCart()
  const { state } = useBundleStore()
  return (
    <>
      <Button
        id="addToQuoteButton"
        onClick={() => {
          if (variant.bundleData) {
            // Add all selected bundles to cart
            for (const { code: id, quantity } of Object.values(state)) {
              if (id && quantity) {
                addToCart(id, quantity, 'favorites')
              }
            }
          } else {
            addToCart(variant.code, quantity, 'favorites')
          }
          ui.open('favorites')
        }}
        color="secondary"
        className="flex-grow sm:flex-none"
        colorTheme={colorTheme}
      >
        {translate('addToQuote')}
      </Button>
    </>
  )
}

export default CallToActions
