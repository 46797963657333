import React from 'react'
import { useState } from 'react'
import Container from '../ui/Container'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import translate, { TranslationKeys } from 'utils/translate'
import { useAppSelector } from 'store'
import cn from 'classnames'
import { CheckIcon } from '@heroicons/react/24/solid'
import ReviewStars from './ReviewStars'
import { useProductLabel } from 'store2/utils'
import dynamic from 'next/dynamic'
import { useVariantData } from 'store2/use-variant-data'
import { useProduct } from './ProductContext'

const DynamicProductInfo = dynamic(() => import('./DynamicProductInfo'), {
  ssr: false,
})

interface Props {
  settings: {
    layout: string
    colorTheme: CommonSectionSettingsColorThemeEnum
  }
  hasFeature?: boolean
}

const GridShop = ({ settings }: Props) => {
  const css = {
    bullet: '',
    grid: 'grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 xl:gap-x-20',
    // gridShop: "py-10 md:py-14 lg:py-10 px-12 flex flex-col bg-neutral-1_",
    gridShop: 'pt-8 md:pt-10 lg:pt-16 flex flex-col relative',
    gridShopGrid: 'sticky top-0',
    gridShopGridCell: '',
    label: 'font-bold text-base mb-3',
    name: 'font-bold text-h-2xl lg:text-h-3xl xl:text-h-4xl max-w-lg',
    desc: 'mt-1 block font-medium text-lg',
    atAGlance: 'mt-5 font-bold text-sm flex flex-col gap-y-0.5',
    atAGlanceBullet: 'flex gap-x-2 items-center',
    text: 'mt-8 font-medium text-base lg:text-sm lg:leading-normal max-w-lg',
  }

  const { variant } = useProduct()

  const vData = useVariantData(variant.allCodes)

  const label = useProductLabel(variant, vData)

  // Layout
  if (settings?.layout === 'Wide') {
    css.grid = 'grid grid-cols-1'
    css.gridShopGrid = 'lg:mt-6 lg:grid lg:grid-cols-2 lg:gap-x-5'
    // css.gridShopGridCell = "lg:p-8 lg:bg-neutral-2 lg:flex lg:flex-col lg:justify-start";
    css.atAGlance = 'mt-7 lg:mt-0 font-bold text-sm'
    css.text =
      'mt-6 font-medium text-base lg:text-sm lg:leading-normal max-w-lg'
  }

  if (settings?.colorTheme === 'Dark') {
    css.label = cn(css.label, 'text-accent')
    css.name = cn(css.name, 'text-white')
    css.desc = cn(css.desc, 'text-primary-2')
    css.atAGlance = cn(css.atAGlance, 'text-white')
    css.text = cn(css.text, 'text-white')
  } else {
    // Default
    css.label = cn(css.label, 'text-accent')
    css.name = cn(css.name, 'text-primary')
    css.desc = cn(css.desc, 'text-primary-2')
    css.atAGlance = cn(css.atAGlance, 'text-primary')
    css.text = cn(css.text, 'text-primary')
  }
  const { showPrices } = useAppSelector((s) => s.channel)
  const [workWithSalesOpen, setWorkWithSalesOpen] = useState(false)

  // Work with SalesRep
  const toggleWorkWithSalesOpen = () => {
    setWorkWithSalesOpen(!workWithSalesOpen)
  }

  return (
    <div className={css.gridShop}>
      <Container layout="FluidFull" height="Grow">
        <div className={css.gridShopGrid}>
          <div className={css.gridShopGridCell}>
            {label && (
              <div className={css.label}>
                {translate(label as TranslationKeys)}
              </div>
            )}
            <h1>
              <span className={css.name}>{variant.title}</span>
              {variant.subtitle && (
                <span className={css.desc}>{variant.subtitle}</span>
              )}
            </h1>
            <ReviewStars variantId={variant.code} className="mt-3" />
            {variant.product.atAGlace && settings?.layout !== 'Wide' && (
              <ul className={css.atAGlance}>
                {variant.product.atAGlace.map((bullet, index: number) => (
                  <li key={index} className={css.atAGlanceBullet}>
                    <CheckIcon className="w-3.5 h-3.5" />
                    {bullet.text}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={css.gridShopGridCell}>
            {variant.product.atAGlace && settings?.layout === 'Wide' && (
              <ul className={css.atAGlance}>
                {variant.product.atAGlace.map((bullet, index: number) => (
                  <li key={index} className={css.bullet}>
                    {bullet.text}
                  </li>
                ))}
              </ul>
            )}
            <p className={css.text}>{variant.desc ?? variant.product.desc}</p>
            <DynamicProductInfo
              colorTheme={settings.colorTheme}
              toggleWorkWithSalesOpen={toggleWorkWithSalesOpen}
              workWithSalesOpen={workWithSalesOpen}
              showPrices={showPrices}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default GridShop
