import { VariantViewModel } from 'framework/common/variantViewModel'
import React, { createContext, useState, useContext } from 'react'

interface ProductContextProps {
  quantity: number
  setQuantity: React.Dispatch<React.SetStateAction<number>>
  variant: VariantViewModel
}

const ProductContext = createContext<ProductContextProps | undefined>(undefined)

interface ProductProviderProps {
  children: React.ReactNode
  initialQuantity: number
  variant: VariantViewModel
}
export const ProductProvider = ({
  children,
  initialQuantity,
  variant,
}: ProductProviderProps) => {
  const [quantity, setQuantity] = useState(initialQuantity)

  return (
    <ProductContext.Provider value={{ quantity, setQuantity, variant }}>
      {children}
    </ProductContext.Provider>
  )
}

export const useProduct = () => {
  const context = useContext(ProductContext)

  if (context === undefined) {
    throw new Error('useProduct must be used within a ProductProvider')
  }

  return context
}
