import React, { FC, useEffect, useMemo } from 'react'
import ProductHeader from './ProductHeader'
import { SectionLayoutSettings } from 'framework/common/types'
import { useAppSelector } from 'store'
import ProductSections from './Sections'
import { TrackProductDetailPage } from 'framework/tracking/common/events'
import Section from 'components/common/Section'
import shouldDebugAnalytics from 'utils/shouldDebugAnalytics'
import { useRouter } from 'next/router'
import { SEOHeader } from 'components/common/Head/Head'
// import ProductRelations from './ProductRelations'
import ProductReviews from './ProductReviews'
import { Variant } from 'framework/strapi/types'
import { mapVariantViewModel } from 'framework/common/variantViewModel'
import ProductRelations from './ProductRelations'
import { ProductProvider } from './ProductContext'
import { getImageUrl4x5 } from 'utils/image'

interface ProductPageProps {
  variant: Variant
}

const ProductPage: FC<ProductPageProps> = ({ variant }) => {
  const router = useRouter()
  const { currency, locale, country } = useAppSelector((s) => s.channel)

  const viewModel = useMemo(
    () => mapVariantViewModel(variant, locale, country || 'en'),
    [variant, locale, country],
  )

  const sectionLayoutSettings: SectionLayoutSettings = {
    hasMarginBottom: true,
  }
  useEffect(() => {
    if (router && variant) {
      // TODO Price
      TrackProductDetailPage({
        currency: currency,
        product: {
          price: 0,
          variantId: variant.code,
          variantName: variant.name,
        },
        debug: shouldDebugAnalytics(router),
      })
    }
  }, [variant, router, currency])

  return (
    <ProductProvider initialQuantity={1} variant={viewModel}>
      <SEOHeader
        title={viewModel.name}
        desc={viewModel.product.metaDescription ?? viewModel.product.desc ?? ''}
        tags={viewModel.product.metaTags?.split(',') ?? []}
        image={
          variant.images[0] ? getImageUrl4x5(variant.images[0].name) : undefined
        }
      />
      <Section layoutSettings={sectionLayoutSettings}>
        <ProductHeader />
      </Section>
      <ProductSections />
      <ProductReviews variantId={variant.code} />
      <ProductRelations />
    </ProductProvider>
  )
}

export default ProductPage
