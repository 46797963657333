import classNames from 'classnames'
import Container from 'components/ui/Container'
import BreadCrumbs from 'components/ui/BreadCrumbs'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import { FC, useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import CallToActions from './CallToActions'
import { useProduct } from './ProductContext'

interface Props {
  colorTheme: CommonSectionSettingsColorThemeEnum
}

const ProductBreadcrumbs: FC<Props> = ({ colorTheme }) => {
  const { variant } = useProduct()

  // Check if product CTAs are visible
  const productCtasVisible = () => {
    const productCtasEl = document.getElementById('productCtas')
    if (typeof window === 'undefined' || !productCtasEl) return false

    return (
      // productCtasEl.getBoundingClientRect().top < window.innerHeight &&
      productCtasEl.getBoundingClientRect().bottom > 0
    )
  }

  const [showBreadcrumbs, setShowBreadcrumbs] = useState<boolean>(false)

  useScrollPosition(({ prevPos, currPos }) => {
    const y = -currPos.y
    const py = -prevPos.y

    // Scrolling up
    if (y < py) {
      setShowBreadcrumbs(false)
    }

    // Scrolling down
    if (y > py) {
      setShowBreadcrumbs(!productCtasVisible())
    }
  }, [])

  return (
    <div
      className={classNames(
        'fixed z-50 bottom-0 lg:bottom-auto lg:top-0 left-0 py-4 md:py-6 w-full bg-white border-t-2 border-white transition-transform duration-200',
        showBreadcrumbs
          ? 'translate-y-0 '
          : 'lg:-translate-y-full translate-y-full',
      )}
    >
      <Container layout="Fluid">
        <div className="flex items-center">
          <div className="hidden md:flex flex-1">
            <BreadCrumbs
              colorTheme={colorTheme}
              breadCrumbs={variant.breadCrumbs}
            />
          </div>
          {variant.availableOnline && (
            <CallToActions
              className="w-full md:w-auto md:flex-shrink-0"
              colorTheme={colorTheme}
              source="breadcrumbs"
            />
          )}
        </div>
      </Container>
    </div>
  )
}

export default ProductBreadcrumbs
