import handleJSONResponse from 'utils/handleResponse'

export const getUrl = (path: string) => `https://enterprise.eleiko.com${path}`

const defaultOptions: RequestInit = {
  method: 'GET',
  headers: { 'content-type': 'application/json' },
}

export const fetchAPI = async <T>(path: string, options?: RequestInit) => {
  const requestUrl = getUrl(path)
  const allOptions: RequestInit = {
    ...defaultOptions,
    ...options,
    headers: { ...defaultOptions.headers, ...(options?.headers ?? {}) },
  }
  console.log('Fetching url:', requestUrl)
  const response = await fetch(requestUrl, allOptions)
  return handleJSONResponse<T>(response)
}

export interface SubscriptionProps {
  email: string
  variantId: string
  subscribe: boolean
  productUrl: string
}

export const subscribeToStocksMail = async (props: SubscriptionProps) => {
  const response = await fetch(`/api/out-of-stock-notification`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(props),
  })
  return response
}

export interface FavoritesInfoProps {
  locale: string
  variantId: string | undefined
}
