import { StarIcon } from '@heroicons/react/24/solid'
import cn from 'classnames'
import { FC, useEffect, useState } from 'react'
import translate from 'utils/translate'

interface ReviewResult {
  sku: string
  questions: number
  relatedQuestions: number
  comments: number
  answers: number
  relatedAnswers: number
  reviews: number
  averageRating: number
  ratingBreakdown: RatingBreakdown
  active: boolean
  ratings: number
}

interface RatingBreakdown {
  '1': number
  '2': number
  '3': number
  '4': number
  '5': number
}

interface ReviewStarsProps {
  variantId: string
  className?: string
}

const ReviewStars: FC<ReviewStarsProps> = ({ variantId, className }) => {
  const [review, setReview] = useState<ReviewResult | null>(null)
  useEffect(() => {
    ;(async () => {
      try {
        const r = await fetch(`/api/reviews?variantId=${variantId}`)
        const json = (await r.json()) as ReviewResult[]
        if (!json || !json.length) return
        setReview(json[0])
      } catch (e) {
        console.error(`Error fetching reviews for variantId: ${variantId}`)
        console.error(e)
      }
    })()
  }, [variantId])

  if (!review || review.ratings == 0) return null

  return (
    <ReviewStarsInner rating={review.averageRating} className={className} />
  )
}

interface ReviewStarsInnerProps {
  rating: number
  className?: string
}

const ReviewStarsInner: FC<ReviewStarsInnerProps> = ({ rating, className }) => {
  const stars = []
  for (let i = 0; i < 5; i++) {
    const filled = i < rating
    stars.push(
      <span key={i} className={filled ? 'text-primary' : 'text-neutral-3'}>
        <StarIcon key={i} className={cn('h-5 w-5')} />
      </span>,
    )
  }

  return (
    <a
      href="#tt-reviews-summary"
      className={cn('flex items-center', className)}
    >
      {stars}
      <span className="ml-2 font-medium text-sm text-primary underline">
        {translate('writeReview')}
      </span>
    </a>
  )
}

export default ReviewStars
