import React, { useMemo } from 'react'
import Container from '../ui/Container'
import NotifyMeDialog from './NotifyDialog'
import GridShop from './GridShop'
import {
  CmsGalleryItem,
  CommonSectionSettingsColorThemeEnum,
  StrapiFileComponent,
} from 'framework/strapi/types'
import { getImageUrl4x5 } from 'utils/image'
import ProductBreadcrumbs from './ProductBreadcrumbs'
import Gallery from 'components/cms/Gallery/Gallery'
import SizeGuide from './SizeGuideDialog'
import { VariantViewModel } from 'framework/common/variantViewModel'
import useUIStore from 'store/ui'
import BreadCrumbs from 'components/ui/BreadCrumbs'
import { useProduct } from './ProductContext'

// TODO Were does this setting come from?
const settings = {
  layout: '',
  colorTheme: '' as CommonSectionSettingsColorThemeEnum,
}

const ProductHeader = () => {
  const ui = useUIStore()

  const css = {
    grid: 'grid grid-cols-1 lg:grid-cols-2 xl:flex lg:gap-x-8 xl:gap-x-20',
    gridGallery: 'relative xl:flex-shrink-0',
    gridGalleryWrapper: 'sticky top-0',
  }

  const { variant } = useProduct()

  const galleryItems = useMemo(() => createGalleryItems(variant), [variant])

  // Temp colorTheme
  const colorTheme = ''
  const captionStyle = null

  return (
    <>
      <NotifyMeDialog />
      {variant.product.sizeGuide?.html && (
        <SizeGuide
          isOpen={ui.isOpen && ui.overlay === 'sizechart'}
          onClose={() => ui.close()}
          sizeGuide={variant.product.sizeGuide.html}
        />
      )}
      <div className="w-full mb-4 lg:absolute lg:top-0 lg:left-0 lg:z-10">
        <Container layout="Fluid">
          <div className="lg:flex lg:justify-end lg:whitespace-nowrap">
            <BreadCrumbs
              layout="ProductHeader"
              breadCrumbs={variant.breadCrumbs}
            />
          </div>
        </Container>
      </div>
      <Container layout="FullFluid" height="Grow" align="Center">
        <div className={css.grid}>
          <div className={css.gridGallery}>
            <div className={css.gridGalleryWrapper}>
              <Gallery
                layout={settings.layout === 'Wide' ? 'Wide' : 'Product'}
                zoom={true}
                priorityItems={(_, i) => i === 0}
                counter={settings.layout !== 'Wide'}
                thumbs={settings.layout !== 'Wide'}
                tw_aspectRatio="aspect-w-4 aspect-h-5"
                tw_lightBoxAspectRatio="aspect-w-4 aspect-h-5"
                id="product-gallery"
                logos={variant.product.certifications}
                items={galleryItems}
                __component="cms.gallery-section"
                channel={null}
                colorTheme={colorTheme}
                captionStyle={captionStyle}
                container="FullFluid"
              />
            </div>
          </div>
          <GridShop settings={settings} />
        </div>
      </Container>
      <ProductBreadcrumbs colorTheme={settings.colorTheme} />
    </>
  )
}

export default ProductHeader

function createGalleryItems(variant: VariantViewModel): CmsGalleryItem[] {
  const alt = `Eleiko ${variant.code}`
  const defaultAsset: StrapiFileComponent = {
    id: '',
    __component: 'cms.gallery-item',
    name: '',
    alternativeText: '',
    caption: '',
    formats: {},
    provider: '',
    url: '',
    mime: 'image/jpeg',
    width: 1024,
    height: 1024,
  }

  const defaultItem: CmsGalleryItem = {
    id: '',
    __component: 'cms.gallery-item',
    asset: defaultAsset,
    externalVideo: null,
    thumbnail: null,
    channel: null,
    caption: null,
    thumbnail_canto: null,
    asset_canto: null
  }
  const images: CmsGalleryItem[] = []
  let baseProductImage: CmsGalleryItem | null = null
  for (const n of variant.images ?? []) {
    // TODO no typings for images
    const src = getImageUrl4x5(n.name)
    const item: CmsGalleryItem = {
      ...defaultItem,
      id: src,
      asset: {
        ...defaultAsset,
        id: src,
        name: `${alt} ${n.name}`,
        url: src,
        alternativeText: `${alt} ${n.name}`,
      },
      caption: n.caption ?? '', // TODO Image captions
    }
    if (n.name.startsWith('bp-')) {
      baseProductImage = item
    } else {
      images.push(item)
    }
  }

  if (baseProductImage) {
    images.push(baseProductImage)
  }

  for (const n of variant.product.productMedia) {
    const item: CmsGalleryItem = {
      ...defaultItem,
      id: n.url,
      asset: {
        ...defaultAsset,
        mime: 'video/mp4',
        id: n.url,
        url: n.url,
        formats: {
          small: {
            mime: 'image/jpeg',
            url: n.thumbUrl ?? '',
            height: 720,
            width: 1280,
            name: 'Youtube',
          },
        },
      },
    }
    images.push(item)
  }

  if (variant.londonDynamicsConfig?.threeDViewUrl) {
    const src = variant.londonDynamicsConfig.threeDViewUrl
    const item: CmsGalleryItem = {
      ...defaultItem,
      id: src,
      asset: {
        ...defaultAsset,
        mime: 'embed-3d',
        id: src,
        url: src,
      },
    }
    images.push(item)
  }

  return images
}
